// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiRate: 'https://7zf888upik.execute-api.us-east-1.amazonaws.com/prod/getCoinPrice?coin=',
  apiArgRate: 'https://api-contenidos.lanacion.com.ar/json//V3/economia/cotizacion/DBNA',
  apiBlueRate1: 'https://api-contenidos.lanacion.com.ar/json//V3/economia/cotizacionblue/DBLUE',
  apiBlueRate: 'https://mercados.ambito.com/home/general',
  apiSiteConfig: 'https://7zf888upik.execute-api.us-east-1.amazonaws.com/prod/getSiteConfig',
  apiTransferwise: 'https://e21feoyhp9.execute-api.us-east-1.amazonaws.com/Development/api/pricer/current-exchange-rate',
  apiTransferwise1: 'https://api.transferwise.com/v1/rates?source={{source}}&target={{target}}',
  currentLocation1: 'http://ip-api.com/json',
  currentLocation2: 'https://ipinfo.io',
  currentLocation3: 'https://ipapi.co/json/',
  currentCoin: 'https://api.ipgeolocationapi.com/countries/',
  api1: 'http://81.7.14.5:8010/api/v1/',
  api2: 'https://monedablue.pythonanywhere.com/api/v1/',
  api: 'https://admin.monedablue.com/api/v1/',
  token: 'bfc5ccdfedcea6666ac2492267e9eba5276a2b73',
  zelle: true,
  spei: true,
  transferwise: false,
  revolut: true,
  rapyd: true,
  airwallex: false,
  ach: false,
  wire: false,
  skrill: true,
  paypal: true,
  paysera: true,
  venmo: true,
  subscripcionApiKey: 'F8G.oqor2+eCgqTEyMow6ma',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
