import {Component, OnInit} from '@angular/core';
import {MxnService} from './services/mxn.service';
import {SiteConfigService} from './services/site-config.service';
import {TranslateService} from '@ngx-translate/core';
import {TransferwiseService} from './services/transferwise.service';
import {WayPayService} from './services/way-pay.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {}
}
